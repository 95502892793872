import React from "react";
import Footer from "components/Footer";

const ContactUs = () => {
  return (
    <>
      <div className="content mt-5 py-5">
        <p className="text-center">
          <strong>
            <u>Contact Us</u>
          </strong>
        </p>
        <p>At Sildenafil.com LLC, we value your feedback and are here to assist you with any questions or concerns you may have. Please feel free to reach out to us through any of the contact methods listed below.&nbsp;</p>
        <p>
          <strong
            style={{
              fontSize: "22px",
              fontWeight: 700
            }}>
            Our Contact Information
          </strong>
        </p>
        <p>
          <strong>
            Business Name:
          </strong>
        </p>
        <p>
          Sildenafil.com LLC
        </p>
        <p>
          <strong>
            Physical Address:
          </strong>
        </p>
        <p>
          2372 Morse Avenue, Suite #377
          <br />
        </p>
        <p>
          Irvine, CA 92614
          <br />
        </p>
        <p>
          <strong>
            Phone Number:
          </strong>
        </p>
        <p>
          (844) 745-3362
        </p>
        <p>
          <strong>
            Email Address:
          </strong>
        </p>
        <p>
          care@sildenafil.com
          <br />
        </p>
        <p>
          We strive to respond to all inquiries as quickly as possible. Whether you have questions about our products, need assistance with an order, or want to provide feedback, our team is here to help.
          <br />
        </p>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
