import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { CreditCardType } from "types";
import { AuthContext } from "Contexts/AuthContext";
import { PaymentNextStepRequest, OrderPaymentRequest, AuthorizePaymentProfile } from "apis";
import Button from "components/Button";
import SelectableCardItem from "./SelectableCardItem";
import NewCardItem from "./NewCardItem";
import useCurrentUser from "hooks/useCurrentUser";
import useLatestCart from "hooks/useLatestCart";
import useCurrentOrder from "hooks/useCurrentOrder";
import { OrderContext } from "Contexts/OrderContext";
import ReactPixel from "react-facebook-pixel";

type Props = {
  selectedCard: CreditCardType | undefined;
  creditCards: any;
  setShowForm: (value: boolean) => void;
  changeSelectedCard: (paymentProfileId: string) => void;
  showForm: boolean;
  buttonText?: string;
  orderMode?: boolean;
};

const SelectableCards = ({
  selectedCard,
  creditCards,
  showForm,
  setShowForm,
  changeSelectedCard,
  buttonText,
  orderMode = false,
}: Props) => {
  const user = useCurrentUser();
  const cart = useLatestCart();
  const order = useCurrentOrder();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>(null);
  const { SelectCreditCard, reloadUser, setShowResumeVisitModal, setLoading } =
    useContext(AuthContext);
  const { setCurrentOrder } = useContext(OrderContext);
  const navigate = useNavigate();

  const handleSelectedItemText = (selectedCard: CreditCardType) => {
    if (creditCards.length && showForm) {
      return <NewCardItem setShowForm={setShowForm} />;
    } else {
      return (
        <SelectableCardItem
          creditCard={selectedCard}
          onSelect={(paymentProfileId: string) =>
            onCardSelect(paymentProfileId)
          }
        />
      );
    }
  };

  const onCardSelect = (paymentProfileId: string) => {
    setShowForm(false);
    SelectCreditCard(paymentProfileId).then(() => {
      changeSelectedCard(paymentProfileId);
    });
  };

  const onSuccessSubmit = () => {
    setLoading(true);

    const button: any = document.querySelector(
      ".ga-initiate-checkout-event, .ga-confirm-and-pay-event, .fb-initiate-checkout-event, .fb-confirm-and-pay-event"
    );

    if (orderMode) {
      if (!isEmpty(order)) {
        OrderPaymentRequest(order.id)
          .then((res) => {
            setShowResumeVisitModal(false);
            setCurrentOrder(undefined);
            reloadUser();
            navigate(res.data.redirect_path);

            // button.click(); // Sending GTM custom confirm and pay event
          })
          .catch((err) => {
            const { data } = err.response;

            setErrors([
              !isEmpty(data.errors.credit_card)
                ? data.errors.credit_card.join(" ")
                : data.message,
            ]);

            setLoading(false);
          });
      }
    } else {
      AuthorizePaymentProfile()
      .then(() => {
        PaymentNextStepRequest(cart?.id)
          .then((res) => {
            setShowResumeVisitModal(false);
  
            ReactPixel.fbq(
              "track",
              "InitiateCheckout",
              { external_id: cart?.id },
              { eventID: "fb_initiate_checkout" }
            );
  
            ReactPixel.fbq(
              "track",
              "Purchase",
              {
                currency: "USD",
                value: Number(cart?.total_price).toFixed(2),
                external_id: cart?.id,
              },
              { eventID: "fb_purchase" }
            );
  
            reloadUser();
  
            navigate(res.data.redirect_path);
  
            button.click(); // Sending GTM custom initiate checkout event
          })
          .catch((err) => {
            console.log(err);
            setErrors(err.response.data.message);
            setLoading(false);
          });
      })
      .catch(() => {
        setErrors("Authorization declined, you might have insufficent funds");
        setLoading(false);
      })
    }
  };

  if (isEmpty(selectedCard)) return <></>;

  return (
    <div className={`credit-cards-dropdown ${showDropdown && "open"}`}>
      <div className="selected-card">
        {/* using an input field for utilizing onFocus and onBlur for easy dropdown handling */}
        <input
          id="selected-card-input"
          type="text"
          readOnly
          onFocus={() => setShowDropdown(true)}
          onBlur={() => setShowDropdown(false)}
        />
        <label htmlFor="selected-card-input">
          {handleSelectedItemText(selectedCard)}
        </label>
      </div>
      {showDropdown && (
        <ul className="credit-cards-list">
          {creditCards.map((creditCard: any) => (
            <SelectableCardItem
              key={creditCard.payment_profile_id}
              creditCard={creditCard}
              onSelect={(paymentProfileId: string) =>
                onCardSelect(paymentProfileId)
              }
              selected={
                creditCard.payment_profile_id ===
                user?.default_payment_profile_id
              }
            />
          ))}
          <NewCardItem setShowForm={setShowForm} />
        </ul>
      )}
      {errors && <span className="text-danger small">{errors}</span>}
      {!showForm && (
        <Button
          classes={`mt-4 font-weight-bold`}
          color="secondary"
          fullWidth={true}
          disabled={isProcessing}
          onClick={onSuccessSubmit}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default SelectableCards;
