import React, { useContext, useState } from "react";
import { isEmpty, set } from "lodash";
import { CartContext } from "Contexts/CartContext";
import { CreateCardRequest } from "apis";
import useCurrentUser from "hooks/useCurrentUser";
import CvvNumberInput from "./CvvNumberInput";
import ExpirationDateInput from "./ExpirationDateInput";
import TextInput from "./TextInput";
import Button from "components/Button";
import { CreditCardType } from "types";
import { AuthContext } from "Contexts/AuthContext";
import { useRollbar } from "@rollbar/react";

declare global {
  interface Window {
    Accept: any;
  }
}

const CreditCardDefaults = {
  card_number: "",
  card_code: "",
  card_type: "",
  expiration_date: "",
  month: "",
  year: "",
  payment_profile_id: "",
  customer_payment_profile_id: "",
  default_payment_profile: true,
  errors: [],
};

const BillingAddressDefaults = {
  first_name: "",
  last_name: "",
  zip: "",
  country: "US",
};

const ErrorsDefaults = {
  first_name: "",
  last_name: "",
  zip: "",
  avs: "",
  card_number: "",
  expiration_date: "",
  card_code: "",
};

type CreditCardFormProps = {
  view?: any;
  onSuccessSubmit: any;
  buttonText?: string;
  orderMode?: boolean;
  setShouldLoading?: any;
  setAuthErrors?: any;
  authErrors?: any;
};

const CreditCardForm = ({
  view,
  onSuccessSubmit,
  buttonText,
  orderMode,
  setShouldLoading,
  setAuthErrors,
  authErrors
}: CreditCardFormProps) => {
  const user = useCurrentUser();
  const [errors, setErrors] = useState<any>(ErrorsDefaults);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const { ReloadCart } = useContext(CartContext);
  const { setLoading } = useContext(AuthContext);
  const rollbar = useRollbar();

  const [creditCard, setCreditCard] = useState<CreditCardType>({
    ...CreditCardDefaults,
    customer_payment_profile_id: user?.authorize_customer_profile_id,
  });
  const [billingAddress, setBillingAddress] = useState(BillingAddressDefaults);
  const [processing, setProcessing] = useState(false);

  const handleExpirationDateChange = (value: any) => {
    const [month, year] = value.split("/");
    const clearedErrors = { ...errors };

    const newCard = {
      ...creditCard,
      expiration_date: `${month}/${year}`,
      month,
      year,
    };

    set(clearedErrors, "expiration_date", "");

    if (!isEmpty(errors["expiration_date"])) setErrors(clearedErrors);
    setCreditCard(newCard);
  };

  const handleCardAttributeChange = (e: any) => {
    const { name, value } = e.target;
    const newCard = { ...creditCard };
    const clearedErrors = { ...errors };

    set(newCard, name, value);
    set(clearedErrors, name, "");

    if (!isEmpty(errors[name])) setErrors(clearedErrors);
    setCreditCard(newCard);
  };

  const handleAddressAttributeChange = (e: any) => {
    const { name, value } = e.target;
    const newBilling = { ...billingAddress };
    const clearedErrors = { ...errors };

    set(newBilling, name, value);
    set(clearedErrors, name, "");

    if (!isEmpty(errors[name])) setErrors(clearedErrors);
    setBillingAddress(newBilling);
  };

  const isCardDataFilled = () => {
    const result =
      !isEmpty(creditCard?.card_code) &&
      !isEmpty(creditCard?.card_number) &&
      !isEmpty(creditCard?.month) &&
      !isEmpty(creditCard?.year);

    return Boolean(result);
  };

  const isBillingDataFilled = () => {
    const result =
      billingAddress?.first_name.length &&
      billingAddress?.last_name.length &&
      billingAddress?.zip.length;

    return !!result;
  };

  const handleFieldChange = (type: string, e: any) => {
    if (authErrors){
      setAuthErrors("");
    }

    switch (type) {
      case "card":
        handleCardAttributeChange(e);
        break;
      case "billing":
        handleAddressAttributeChange(e);
        break;
      case "expiration":
        handleExpirationDateChange(e);
        break;
    }

    if (view !== "account_view") {
      // Check if all required fields are filled
      setIsFormFilled(isCardDataFilled() && isBillingDataFilled());
    }
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const errors = {
      card_number: [],
      expiration_date: [],
      card_code: [],
      first_name: [],
      last_name: [],
      address: [],
      city: [],
      state: [],
      zip: [],
    };

    let isFormFilled = true;

    if (!isFormFilled) return;

    const payload = {
      authData: {
        apiLoginID: process.env.REACT_APP_AUTHORIZE_API_LOGIN_ID,
        clientKey: process.env.REACT_APP_AUTHORIZE_CLIENT_KEY,
      },
      cardData: {
        cardNumber: creditCard.card_number,
        cardCode: creditCard.card_code,
        month: creditCard.month,
        year: creditCard.year,
        customerPaymentProfileId: creditCard.customer_payment_profile_id,
      },
      validationMode: process.env.REACT_APP_AUTHORIZE_VALIDATION_MODE,
    };

    setProcessing(true);

    window.Accept.dispatchData(payload, (response: any) => {
      const {
        messages: { resultCode, message },
        opaqueData,
      } = response;
      let newErrors = { ...errors };

      if (resultCode !== "Ok") {
        // eslint-disable-next-line array-callback-return
        message.map((err: any) => {
          switch (err.code) {
            case "E_WC_05": {
              if (!errors.card_number) {
                errors.card_number = [];
              }
              newErrors = { ...newErrors, card_number: err.text };
              break;
            }
            case "E_WC_06":
            case "E_WC_07": {
              if (!errors.expiration_date) {
                errors.expiration_date = [];
              }
              newErrors = { ...newErrors, expiration_date: err.text };
              break;
            }
            case "E_WC_08": {
              if (!errors.expiration_date) {
                errors.expiration_date = [];
              }
              newErrors = { ...newErrors, expiration_date: err.text };
              break;
            }
            case "E_WC_15":
              if (!errors.card_code) {
                errors.card_code = [];
              }
              newErrors = { ...newErrors, card_code: err.text };
              break;
            default:
              return null;
          }
        });

        setErrors(newErrors);
        setProcessing(false);
      } else {
        const paymentProfileParams = {
          billing_address: billingAddress,
          opaque_data: opaqueData,
        };

        CreateCardRequest(paymentProfileParams)
          .then((resp: any) => {
            setLoading(true);

            onSuccessSubmit(resp.data.credit_cards);
          })
          .catch((error: any) => {
            const card = { ...creditCard };

            if (error.response?.data.errors?.cardNumber) {
              setErrors({
                ...errors,
                card_number: error.response?.data.errors?.cardNumber,
              });
            }

            if (error.response?.data.errors?.cardCode) {
              setErrors({
                ...errors,
                card_code: error.response?.data.errors?.cardCode,
              });
            }

            if (error.response?.data.errors?.avs) {
              setErrors({
                ...errors,
                avs: error.response?.data.errors?.avs,
              });
              rollbar.error(error.response?.data.errors);
            }

            setCreditCard(card);

            setShouldLoading(false);
          })
          .finally(() => {
            ReloadCart();
            setProcessing(false);
          });
      }
    });
  };

  const { first_name, last_name, zip } = billingAddress;
  const { card_number, card_code, month, year } = creditCard;

  return (
    <form onSubmit={handleFormSubmit} className="credit-card-form">
      <>
        <div className="d-flex justify-content-between fields-half">
          <div className="field-wrapper">
            <TextInput
              type="text"
              label="First name"
              name="first_name"
              value={first_name}
              onChange={(e: any) => handleFieldChange("billing", e)}
              errors={errors.first_name}
              required
            />
            {!isEmpty(errors.first_name) && (
              <label className="form-error">{errors.first_name}</label>
            )}
          </div>
          <div className="field-wrapper">
            <TextInput
              type="text"
              label="Last name"
              name="last_name"
              value={last_name}
              onChange={(e: any) => handleFieldChange("billing", e)}
              errors={errors.last_name}
              required
            />
            {!isEmpty(errors.last_name) && (
              <label className="form-error">{errors.last_name}</label>
            )}
          </div>
        </div>

        <div className="d-flex">
          <div className="field-wrapper w-100">
            <TextInput
              type="tel"
              label="Card Info"
              name="card_number"
              value={card_number}
              className={`input lg no-mouseflow mouseflow-hidden ${
                !isEmpty(errors.card_number) ? "error-border" : ""
              }`}
              onChange={(e: any) => handleFieldChange("card", e)}
              required
            />
            {!isEmpty(errors.card_number) && (
              <label className="form-error">{errors.card_number}</label>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between fields-two-third">
          <div className="field-wrapper">
            <ExpirationDateInput
              type="tel"
              label="Expiration"
              name="expiration_date"
              value={`${month}/${year}`}
              inputClasses={
                !isEmpty(errors.expiration_date) ? "error-border" : ""
              }
              onChange={(e: any) => handleFieldChange("expiration", e)}
              required
              placeholder="MM/YY"
            />
            {!isEmpty(errors.expiration_date) && (
              <label className="form-error">{errors.expiration_date}</label>
            )}
          </div>
          <div className="field-wrapper">
            <CvvNumberInput
              type="tel"
              label="CVV"
              name="card_code"
              value={card_code}
              inputClasses={!isEmpty(errors.card_code) ? "error-border" : ""}
              onChange={(e: any) => handleFieldChange("card", e)}
              required
            />
            {!isEmpty(errors.card_code) && (
              <label className="form-error">{errors.card_code}</label>
            )}
          </div>
          <div className="field-wrapper">
            <TextInput
              type="tel"
              label="Zip Code"
              name="zip"
              value={zip}
              customClass={!isEmpty(errors.zip) ? "error-border" : ""}
              onChange={(e: any) => handleFieldChange("billing", e)}
              required
            />
            {!isEmpty(errors.zip) && (
              <label className="form-error">{errors.zip}</label>
            )}
          </div>
        </div>
      </>

      {errors?.avs && <span className="input__error my-2">Transaction has been declined</span>}
      {authErrors && <span className="input__error my-2">{authErrors}</span>}
      <div className={`form-actions`}>
        <Button
          classes={`font-weight-bold`}
          type="submit"
          color="secondary"
          fullWidth={true}
          disabled={processing}
        >
          {buttonText}
        </Button>
      </div>
    </form>
  );
};

export default CreditCardForm;
