import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty, find } from "lodash";
import { useRollbar } from "@rollbar/react";
import {
  FetchCardsRequest,
  PaymentNextStepRequest,
  OrderPaymentRequest,
  AuthorizePaymentProfile,
} from "apis";
import { AuthContext } from "Contexts/AuthContext";
import useCurrentUser from "hooks/useCurrentUser";
import useCurrentOrder from "hooks/useCurrentOrder";
import useLatestCart from "hooks/useLatestCart";
import CreditCardForm from "./CreditCardForm";
import SelectableCards from "./SelectableCards";
import { CreditCardType } from "types";

import "./styles.scss";
import { CartContext } from "Contexts/CartContext";
import { OrderContext } from "Contexts/OrderContext";
import ReactPixel from "react-facebook-pixel";

type Props = {
  buttonText?: string;
  orderMode?: boolean;
};

const CreditCardPicker = ({
  buttonText = "Complete My Order",
  orderMode = false,
}: Props) => {
  const user = useCurrentUser();
  const order = useCurrentOrder();
  const { setLoading, reloadUser } = useContext(AuthContext);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [shouldLoading, setShouldLoading] = useState<boolean>(false);
  const [creditCards, setCreditCards] = useState<any>([]);
  const [selectedCard, setSelectedCard] = useState<CreditCardType | undefined>(
    undefined
  );
  const [authErrors, setAuthErrors] = useState<string>("");
  const [errors, setErrors] = useState<Array<string>>([]);

  const cart = useLatestCart();
  const navigate = useNavigate();
  const { ReloadCart, setLatestCart } = useContext(CartContext);
  const { setCurrentOrder } = useContext(OrderContext);
  const rollbar = useRollbar();

  useEffect(() => {
    FetchCardsRequest()
      .then((res) => {
        const cards = res.data.credit_cards;

        if (cards.length) {
          setCreditCards(cards);
          setSelectedCard(
            find(cards, {
              payment_profile_id: user?.default_payment_profile_id,
            })
          );
        } else {
          setShowForm(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps

    setLoading(shouldLoading);
  }, []);

  // Form submission on successful
  const onSuccessSubmit = (newCreditCards: []) => {
    setCreditCards(newCreditCards);
    setLoading(true);

    const button: any = document.querySelector(
      ".ga-initiate-checkout-event, .ga-confirm-and-pay-event, .fb-initiate-checkout-event, .fb-confirm-and-pay-event"
    );

    if (orderMode) {
      if (!isEmpty(order)) {
        OrderPaymentRequest(order.id)
          .then((res) => {
            reloadUser().then(() => {
              setLatestCart(undefined);
              setCurrentOrder(undefined);
              navigate(res.data.redirect_path);
              // button.click(); // Sending GTM custom confirm and pay event
            });
          })
          .catch((err) => {
            if (!isEmpty(err.credit_card)) {
              setErrors(err.credit_card);
            } else {
              setErrors(err);
            }
            rollbar.error(err);
            setLoading(false);
          });
      }
    } else {
      AuthorizePaymentProfile()
      .then(() => {
        PaymentNextStepRequest(cart?.id)
        .then((res) => {
          reloadUser().then(() => {
            setLatestCart(undefined);
            localStorage.removeItem("intro-answers");

            ReactPixel.fbq(
              "track",
              "InitiateCheckout",
              { external_id: cart?.id },
              { eventID: "fb_initiate_checkout" }
            );

            ReactPixel.fbq(
              "track",
              "Purchase",
              {
                currency: "USD",
                value: Number(cart?.total_price).toFixed(2),
                external_id: cart?.id,
              },
              { eventID: "fb_purchase" }
            );

            navigate(res.data.redirect_path);
            button.click(); // Sending GTM initiate checkout custom event
          });
        })
        .catch((err) => {
          rollbar.error(err);
          setLoading(false);
        });
      })
      .catch(() => {
        setAuthErrors("Authorization declined, you might have insufficent funds");
        setLoading(false);
      })
    }
  };

  const changeSelectedCard = (paymentProfileId: string) => {
    setSelectedCard(
      find(creditCards, { payment_profile_id: paymentProfileId })
    );
  };

  const displayContent = () => {
    if (showForm && creditCards.length) {
      return (
        <>
          <SelectableCards
            selectedCard={selectedCard}
            creditCards={creditCards}
            showForm={showForm}
            setShowForm={setShowForm}
            changeSelectedCard={(paymentProfileId: string) =>
              changeSelectedCard(paymentProfileId)
            }
            buttonText={buttonText}
            orderMode={orderMode}
          />
          <CreditCardForm
            onSuccessSubmit={(cc: any) => onSuccessSubmit(cc)}
            buttonText={buttonText}
            orderMode={orderMode}
            setShouldLoading={setShouldLoading}
            authErrors={authErrors}
            setAuthErrors={setAuthErrors}
          />
        </>
      );
    }

    if (!showForm && creditCards.length) {
      return (
        <SelectableCards
          selectedCard={selectedCard}
          creditCards={creditCards}
          showForm={showForm}
          setShowForm={setShowForm}
          changeSelectedCard={(paymentProfileId: string) =>
            changeSelectedCard(paymentProfileId)
          }
          buttonText={buttonText}
          orderMode={orderMode}
        />
      );
    }

    if (showForm && !creditCards.length) {
      return (
        <CreditCardForm
          onSuccessSubmit={(cc: any) => onSuccessSubmit(cc)}
          buttonText={buttonText}
          setShouldLoading={setShouldLoading}
          authErrors={authErrors}
          setAuthErrors={setAuthErrors}
        />
      );
    }

    return <></>;
  };

  return (
    <>
      {displayContent()}
      {!isEmpty(errors) && (
        <div className="text-danger small">{errors.map((error) => error)}</div>
      )}
    </>
  );
};

export default CreditCardPicker;
