import React from "react";
import Footer from "components/Footer";

const ShippingPolicy = () => {
  return (
    <>
      <div className="content mt-5 py-5">
        <p className="text-center">
          <strong>
            <u>Shipping Policy</u>
          </strong>
        </p>
        <p>
          At Sildenafil.com, we strive to provide fast and reliable shipping to ensure you receive your products promptly. Please review our shipping policy below for details on where we ship, our shipping methods, and associated charges.&nbsp;</p>
        <p>
          <strong>Shipping Destinations</strong>
        </p>
        <p>
          We currently ship to the following states in the United States:
        </p>
        <ol>
          <li>
            Alaska
          </li>
          <li>
            Arizona
          </li>
          <li>
            California
          </li>
          <li>
            Colorado
          </li>
          <li>
            Connecticut
          </li>
          <li>
            Delaware
          </li>
          <li>
            District of Columbia
          </li>
          <li>
            Florida
          </li>
          <li>
            Georgia
          </li>
          <li>
            Hawaii
          </li>
          <li>
            Idaho
          </li>
          <li>
            Illinois
          </li>
          <li>
            Indiana
          </li>
          <li>
            Iowa
          </li>
          <li>
            Maine
          </li>
          <li>
            Massachusetts
          </li>
          <li>
            Michigan
          </li>
          <li>
            Minnesota
          </li>
          <li>
            Missouri
          </li>
          <li>
            Montana
          </li>
          <li>
            New Hampshire
          </li>
          <li>
            New Jersey
          </li>
          <li>
            New Mexico
          </li>
          <li>
            New York
          </li>
          <li>
            North Carolina
          </li>
          <li>
            North Dakota
          </li>
          <li>
            Ohio
          </li>
          <li>
            Pennsylvania
          </li>
          <li>
            Rhode Island
          </li>
          <li>
            South Carolina
          </li>
          <li>
            South Dakota
          </li>
          <li>
            Texas
          </li>
          <li>
            Utah
          </li>
          <li>
            Vermont
          </li>
          <li>
            Washington
          </li>
          <li>
            Wisconsin
          </li>
          <li>
            Wyoming
          </li>
        </ol>
        <p>
          <strong>
            Shipping Charges
          </strong>
        </p>
        <ol>
          <li>
            Free Shipping: Orders over $45 qualify for free shipping.
          </li>
          <li>
            USPS Express: $27.00
          </li>
          <li>
            USPS Priority Mail: $10.00
          </li>
        </ol>
        <p>
          <strong>
            Shipping Methods
          </strong>
        </p>
        <p>
          We use the United States Postal Service (USPS) for all shipments. You can select your preferred shipping method during checkout:
          <br />
        </p>
        <ol>
          <li>
            USPS Express
          </li>
          <li>
            USPS Priority Mail
          </li>
        </ol>
        <p>
          <strong>
            Handling Time
          </strong>
        </p>
        <p>
          We aim to process and ship all orders within 24 hours of receiving them. Orders placed on weekends or holidays will be processed the next business day.
        </p>
        <p>
          <strong>
            Shipping Time
          </strong>
        </p>
        <p>
          USPS Priority - Packages are usually delivered within 3 to 7 business days after shipping. However, in rare cases, delays can occur due to weather, carrier issues, or other unforeseen circumstances. In these situations, your order may take up to 20 days to arrive. If your package does not arrive within this timeframe, we will gladly resend your order at no additional charge.
          <br />
        </p>
        <p>
          USPS Express - Packages are delivered within 1 to 2 business days after it has been shipped. Please note that selecting express shipping does not expedite the prescription approval process.
          <br />
        </p>
        <p>
          <strong>
            Shipping Notification
          </strong>
        </p>
        <p>
          An email containing a tracking link will be sent to you as soon as your package has been shipped. This allows you to monitor the delivery status of your order.
          <br />
        </p>
        <p>
          <strong>
            Contact Us
          </strong>
        </p>
        <p>
          If you have any questions or concerns regarding our shipping policy, please feel free to contact us at (844) 745-3362 or care@sildenafil.com.
          <br />
        </p>
      </div>
      <Footer />
    </>
  );
};

export default ShippingPolicy;
