import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import DrugInformation from "components/Modals/DrugInforamtionModal";
import logo from "../../assets/images/home/logo.svg";
import logoPharmacy from "../../assets/images/home/logo-pharmacy.png";
import Translator from "../../components/Translator";
import useCurrentUser from "hooks/useCurrentUser";

import "./styles.scss";

const Footer = () => {
  const [showDrugInformation, setShowDrugInformation] = useState<boolean>(false);
  const [activeProduct, setActiveProduct] = useState("");
  const currentUser = useCurrentUser();
  const handleDrugInformation = (productName: string) => {
    setActiveProduct(productName);
    setShowDrugInformation(true);
  };

  return (
    <>
      <div className="ab-wrapper ab-1">
        <div className="homepage-container">
          <footer className="landing-page-footer">
            <div className="content">
              <div className="column-wrapper">
                <div className="footer-contact footer-column">
                  <ReactSVG src={logo} wrapper="svg" />
                  <p></p>
                  <a href="tel:(844) 745-3362">
                    <Translator path="footer.contact.contactUs" />
                    (844) 745-3362
                  </a>
                  <p className="bold">
                    <Translator path="footer.contact.timing" />
                  </p>
                  <p>
                    <Translator path="footer.contact.assistanceText" />
                  </p>
                </div>
                <div className="support footer-column">
                  <h5 className="h5">
                    <Translator path="footer.support.supportText" />
                  </h5>
                  <Link to="/">
                    <Translator path="footer.links.toHome" />
                  </Link>
                  <a href="/#process">
                    <Translator path="footer.links.toHowItWorks" />
                  </a>
                  <a href="/#help">
                    <Translator path="footer.links.toFaq" />
                  </a>
                  {!currentUser ? (
                    <Link to="/sign-in">
                      <Translator path="footer.links.toMemberLogin" />
                    </Link>
                  ) : ("")}
                  <Link to="/contact_us">
                    <Translator path="footer.baseLinks.contact_us" />
                  </Link>
                </div>
                <div className="medication-info footer-column">
                  <h5 className="h5">
                    <Translator path="footer.medicationInfo" />
                  </h5>
                  <button onClick={() => handleDrugInformation("Sildenafil")}>
                    <Translator path="footer.links.sildenafil" />
                  </button>
                  <button onClick={() => handleDrugInformation("Tadalafil")}>
                    <Translator path="footer.links.tadalafil" />
                  </button>
                  <DrugInformation
                    show={showDrugInformation}
                    setShow={setShowDrugInformation}
                    productName={activeProduct}
                  />
                </div>
                <div className="for-doctors footer-column">
                  <h5 className="h5">
                    <Translator path="footer.pharmacyInfo.forDoctors" />
                  </h5>
                  <p>
                    <Translator path="footer.pharmacyInfo.address" />
                  </p>
                </div>
              </div>
              <div className="pharmacy-info footer-column full-width">
                <div className="row-wrapper">
                  <div className="row images mx-0">
                    <a
                      target="_blank"
                      className="pharmacy-link"
                      href="https://safe.pharmacy/buy-safely/?url=sildenafil.com"
                      rel="noreferrer">
                      <img src={logoPharmacy} alt="pharmacy logo" />
                      <p className="text-center">
                        <Translator path="footer.pharmacyLogo.whatDoesItMeantToBe" />
                        <span style={{ color: "#13A89E" }}>
                          <Translator path="footer.pharmacyLogo.pharmacy" />
                        </span>
                        <Translator path="footer.pharmacyLogo.verified" />
                        <span style={{ color: "#2BB673" }}>
                          <Translator path="footer.pharmacyLogo.wwwDotsafe" />
                        </span>
                        <span style={{ color: "#13A89E" }}>
                          <Translator path="footer.pharmacyLogo.dotComPharmacy" />
                        </span>
                      </p>
                    </a>
                    <div>
                      <a
                        target="_blank"
                        href="https://www.legitscript.com/websites/?checker_keywords=sildenafil.com"
                        rel="noreferrer">
                        <img
                          src="https://static.legitscript.com/seals/731797.png"
                          alt="legit-script"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="row links mx-0 footer-links">
                    <Link to="/terms-of-use">
                      <Translator path="footer.baseLinks.termsOfUse" />
                    </Link>
                    <span>•</span>
                    <Link to="/privacy-policy">
                      <Translator path="footer.baseLinks.privacyPolicy" />
                    </Link>
                    <span>•</span>
                    <Link to="/hipaa">
                      <Translator path="footer.baseLinks.hipaa" />
                    </Link>
                    <span>•</span>
                    <Link to="/disclaimer">
                      <Translator path="footer.baseLinks.disclaimer" />
                    </Link>
                    <span>•</span>
                    <Link to="/return_and_refund_policy">
                      <Translator path="footer.baseLinks.return_and_refund_policy" />
                    </Link>
                    <span>•</span>
                    <Link to="/shipping_policy">
                      <Translator path="footer.baseLinks.shipping_policy" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="general-info footer-column full-width">
                <p>
                  <Translator path="footer.copyrightText" />
                </p>
                <p className="copyright mt-2">
                  <Translator path="footer.copyright" />
                </p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Footer;
