import React from "react";
import Footer from "components/Footer";

const ReturnAndRefundPolicy = () => {
  return (
    <>
      <div className="content mt-5 py-5">
        <p className="text-center">
          <strong>
            <u>Refund & Return Policy</u>
          </strong>
        </p>
        <p>
          At Sildenafil.com, we are committed to providing quality products and excellent customer service. Please review our refund and return policy below for details on how we handle cancellations, defective products, and other return-related concerns.&nbsp;</p>
        <p>
          <strong>Order Cancellations</strong>
        </p>
        <p>
          We understand that sometimes you may need to cancel an order. If you wish to cancel your order, please contact us immediately before the order is shipped. Once the order is shipped, cancellations will no longer be possible.
        </p>
        <p>
          <strong>
            How to Cancel an Unshipped Order:
          </strong>
        </p>
        <ol>
          <li>
            Contact our customer service team via phone at (714) 276-2040
          </li>
          <li>
            Provide your order number and reason for cancellation.
          </li>
          <li>
            If your order has not yet been shipped, we will process the cancellation and refund your original payment method.
          </li>
        </ol>
        <p>
          <strong>
            Defective or Damaged Products
          </strong>
        </p>
        <p>
          If you receive a product that is incorrect, damaged by the carrier, or defective in another way, we will replace it and send you a new product free of charge.
          <br />
        </p>
        <p>
          How to Report a Defective or Damaged Product:
          <br />
        </p>
        <ol>
          <li>
            Contact our customer service team at (844) 745-3362 within 3 days of receiving the product.
          </li>
          <li>
            Provide details and photos of the damage or defect, along with your order number.
          </li>
          <li>
            Once verified, we will ship a replacement product using USPS Priority Mail at no additional cost to you.At our discretion, we may request that the incorrect, damaged, or otherwise defective package be returned to us.
          </li>
        </ol>
        <p>
          <strong>
            Returns
          </strong>
        </p>
        <p>
          Due to the nature of our products, we do not accept returns for a refund. If there is an issue with your medication, such as damage during transit, we will replace the item at no cost to you. However, if the medication has been opened, we cannot offer a refund or replacement. If a replacement is sent,  at our discretion, we may request that the incorrect, damaged, or otherwise defective package be returned to us.
        </p>
        <p>
          <strong>
            Refunds
          </strong>
        </p>
        <p>
          If a refund is required (e.g., for a canceled unshipped order), we will process the refund within 3 business days using the same form of payment used for the original purchase. Refunds will be credited to your original credit card. Please note that it typically takes 3 to 7 business days for the refunded amount to appear on your statement, depending on your card issuer and the merchant.
        </p>
        <p>
          <strong>
            Contact Us
          </strong>
        </p>
        <p>
          If you have any questions or concerns regarding our Refund & Return Policy, please contact us at (844) 745-3362 or care@sildenafil.com.
          <br />
        </p>
      </div>
      <Footer />
    </>
  );
};

export default ReturnAndRefundPolicy;
