import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Translator from "../../components/Translator";
import useCurrentUser from "../../hooks/useCurrentUser";
import chevronDown from "../../assets/images/home/chevron-down.svg";
import closeIcon from "../../assets/images/home/close.svg";
import medicationsIcon from "../../assets/images/account/medications.svg";
import currentOrderIcon from "../../assets/images/account/current-order.svg";
import medicalVisitsIcon from "../../assets/images/account/medical-visits.svg";
import orderHistoryIcon from "../../assets/images/account/order-history.svg";
import paymentOptionsIcon from "../../assets/images/account/payment-options.svg";
import shippingAddressIcon from "../../assets/images/account/shipping-address.svg";
import profileIcon from "../../assets/images/account/profile.svg";
import notificationsIcon from "../../assets/images/account/notifications.svg";
import cancelOrderIcon from "../../assets/images/account/cancel-order-process.svg";
import "./styles.scss";
import { GetProductVariantRequest } from "apis";
import { AuthContext } from "Contexts/AuthContext";

const AccountSidebar = () => {
  const [showList, setShowList] = useState(false);
  const currentUser = useCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    setDefaultProductVariant,
  } = useContext(AuthContext);

  useEffect(() => {
    GetProductVariantRequest().then((res: any) => {
      setDefaultProductVariant(res.data.default_product_variant);
    });
  }, [currentUser]);

  if (!currentUser) {
    navigate("/");
    return <></>;
  }

  return (
    <div className={`account-nav ${showList && "is-open"}`}>
      <div className="account-nav__header">
        <Translator path="account.navbar.welcome" /> {currentUser.first_name}!
        <button
          className="account-card__toggle account-nav__toggle"
          onClick={() => {
            setShowList(!showList);
          }}
        >
          <ReactSVG
            src={showList ? closeIcon : chevronDown}
            className={`account-card__toggle-control account-card__toggle-${showList ? "close" : "open"
              }`}
            wrapper={"svg"}
          />
        </button>
      </div>
      <div className="account-nav__items">
        <Link
          className={`account-nav__item ${location?.pathname === "/account/order-refill"
            }`}
          to="/account/order-refill"
          onClick={() => setShowList(false)}
        >
          <div className="account-nav__image">
            <ReactSVG src={medicationsIcon} />
          </div>
          <div className="account-nav-title account-nav-title">
            <p className="menu-title">
              <Translator path="account.navbar.yourMedications" />
            </p>
            <small className="menu-subtitle">
              <Translator path="account.navbar.orderMeds" />
            </small>
          </div>
        </Link>
        <Link
          className={`account-nav__item ${location?.pathname === "/account/current-order" && "active"
            }`}
          to="/account/current-order"
          onClick={() => setShowList(false)}
        >
          <div className="account-nav__image">
            <ReactSVG src={currentOrderIcon} />
          </div>
          <div className="account-nav-title">
            <p className="menu-title">
              <Translator path="account.navbar.currentOrder" />
            </p>
            <small className="menu-subtitle">
              <Translator path="account.navbar.orderStatus" />
            </small>
          </div>
        </Link>
        <Link
          className={`account-nav__item ${location?.pathname === "/account/medical-visits" && "active"
            }`}
          to="/account/medical-visits"
          onClick={() => setShowList(false)}
        >
          <div className="account-nav__image">
            <ReactSVG src={medicalVisitsIcon} />
          </div>
          <div className="account-nav-title">
            <p className="menu-title">
              <Translator path="account.navbar.medicalVisits" />
            </p>
            <small className="menu-subtitle">
              <Translator path="account.navbar.existingOrPastVisits" />
            </small>
          </div>
        </Link>
        <Link
          className={`account-nav__item ${location?.pathname === "/account/orders-history" && "active"
            }`}
          to="/account/orders-history"
          onClick={() => setShowList(false)}
        >
          <div className="account-nav__image">
            <ReactSVG src={orderHistoryIcon} />
          </div>
          <div className="account-nav-title">
            <p className="menu-title">
              <Translator path="account.navbar.ordersHistory" />
            </p>
            <small className="menu-subtitle">
              <Translator path="account.navbar.pastOrders" />
            </small>
          </div>
        </Link>
        <Link
          className={`account-nav__item ${location?.pathname === "/account/payment-options" && "active"
            }`}
          to="/account/payment-options"
          onClick={() => setShowList(false)}
        >
          <div className="account-nav__image">
            <ReactSVG src={paymentOptionsIcon} />
          </div>
          <div className="account-nav-title">
            <p className="menu-title">
              <Translator path="account.navbar.paymentOptions" />
            </p>
            <small className="menu-subtitle">
              <Translator path="account.navbar.editPaymentOptions" />
            </small>
          </div>
        </Link>
        <Link
          className={`account-nav__item ${location?.pathname === "/account/shipping-address" && "active"
            }`}
          to="/account/shipping-address"
          onClick={() => setShowList(false)}
        >
          <div className="account-nav__image">
            <ReactSVG src={shippingAddressIcon} />
          </div>
          <div className="account-nav-title">
            <p className="menu-title">
              <Translator path="account.navbar.ShippingAddress" />
            </p>
            <small className="menu-subtitle">
              <Translator path="account.navbar.editAddress" />
            </small>
          </div>
        </Link>
        <Link
          className={`account-nav__item ${location?.pathname === "/account/profile" && "active"
            }`}
          to="/account/profile"
          onClick={() => setShowList(false)}
        >
          <div className="account-nav__image">
            <ReactSVG src={profileIcon} />
          </div>
          <div className="account-nav-title">
            <p className="menu-title">
              <Translator path="account.navbar.profile" />
            </p>
            <small className="menu-subtitle">
              <Translator path="account.navbar.editInfo" />
            </small>
          </div>
        </Link>
        <Link
          className={`account-nav__item ${location?.pathname === "/account/notifications" && "active"
            }`}
          to="/account/notifications"
          onClick={() => setShowList(false)}
        >
          <div className="account-nav__image">
            <ReactSVG src={notificationsIcon} />
          </div>
          <div className="d-flex flex-column">
            <p className="menu-title">
              <Translator path="account.navbar.notifications" />
            </p>
            <small className="menu-subtitle">
              <Translator path="account.navbar.editNotifications" />
            </small>
          </div>
        </Link>
        <Link
          className={`account-nav__item ${location?.pathname === "/account/cancel-order-process" && "active"
            }`}
          to="/account/cancel-order-process"
          onClick={() => setShowList(false)}
        >
          <div className="account-nav__image">
            <ReactSVG src={cancelOrderIcon} />
          </div>
          <div className="d-flex flex-column">
            <p className="menu-title">
              <Translator path="account.navbar.cancelOrder" />
            </p>
            <small className="menu-subtitle">
              <Translator path="account.navbar.viewCancellationProcess" />
            </small>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AccountSidebar;
